'use strict'
import "friendly-challenge/widget";

export function initInScope($scope) {
    let $recaptchaForm = $scope.find('.js-friendly-recaptcha');

    $recaptchaForm.each(function () {

        let $submitBtn = $(this).find('.btn:submit');
        let $additionalSubmitBtn = $scope.find('.js-friendly-recaptcha__additional-button');

        if(!($submitBtn).hasClass('recaptcha-finished')) {
            $submitBtn.attr('disabled','disabled');
        }

        if($additionalSubmitBtn.length) {
            if(!($additionalSubmitBtn).hasClass('recaptcha-finished')) {
                $additionalSubmitBtn.attr('disabled','disabled');
            }
        }

        window.doneCallback = doneCallback;
        function doneCallback(solution) {
            //console.log("CAPTCHA completed succesfully, solution:", solution);
            $submitBtn.addClass('recaptcha-finished');
            $submitBtn.removeAttr('disabled');

            if($additionalSubmitBtn.length) {
                $additionalSubmitBtn.addClass('recaptcha-finished');
                $additionalSubmitBtn.removeAttr('disabled');
            }
        }
    })
}

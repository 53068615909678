"use strict";

import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import {loadParsley} from "@elements/parsley-bootstrap-validation";
import formDataEntries from "form-data-entries";
import {showNotification} from "@elements/alert-notification";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    let pendingRequest;

    let $stepViews = $scope.find('.js-step-view');
    let $prevBtn = $scope.find('.js-step-view__prev');
    let $nextBtn = $scope.find('.js-step-view__next');
    let $stepForms = $scope.find('.js-step-view__step-form');
    let $clearUserDataBtn = $scope.find('.js-step-view__clear-user-data');

    navigateTo(0, $stepViews); // Start at the beginning

    $nextBtn.on('click', function (evt) {
        evt.preventDefault();
        let $stepView = $(this).closest('.js-step-view');
        navigateTo(curIndex($stepView) + 1, $stepView);
    });

    $prevBtn.click(function (evt) {
        evt.preventDefault();
        let $stepView = $(this).closest('.js-step-view');
        navigateTo(curIndex($stepView) - 1, $stepView);
    });

    $scope.find('.js-step-view__reload').on('click', function (evt) {
        evt.preventDefault();
        let url = $(this).attr('data-reload-url');

        if(url === '#') {
            location.reload();
        } else {
            window.location.href = url;
        }
    });

    // reload page when modal is closed after last step
    $scope.find('.js-size-advisor-modal').on('hidden.bs.modal', function () {
       let $modal = $(this);
       let $stepView = $modal.find('.js-step-view');
       let stepCnt = $stepView.find('.js-step-view__step').length;

       if (stepCnt - 1 === curIndex($stepView)) {
           $modal.find('.js-step-view__reload').click();
       }
    });

    $clearUserDataBtn.click(function (evt) {
        evt.preventDefault();
        let url = $(this).attr('data-url');

        pendingRequest = fetch(url, {
            method: 'POST',
            headers: {
                'pragma' : 'no-cache',
                'cache-control': 'no-cache'
            }
        });

        // showNotification(pendingRequest);
        location.reload();
    });

    $stepForms.each(function() {
        let $form = $(this);
        let $formElements = $form.find('input,select,textarea');

        // $formElements.on('input', function(evt) {
        //     loadParsley().then(function () {
        //         if ($form.data('Parsley')) {
        //             $form.data('Parsley').whenValid().then(() => {
        //                 $form.find('.js-step-view__step-submit').attr('disabled', false);
        //             })
        //         }
        //     });
        // });

        $form.on('submit', function(evt) {
            evt.preventDefault();

            loadParsley().then(function () {
                if ($form.data('Parsley')) {
                    $form.data('Parsley').whenValid().then(() => {
                        handleSubmission($form);
                    })
                }
            });
        })
    });

    function handleSubmission($form) {
        let $stepView = $form.closest('.js-step-view');
        let $stepFormLoading = $stepView.find('.js-step-view__loading');

        $stepFormLoading.attr('hidden', false);
        if(pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        const data = formDataEntries($form[0]);
        let params = new URLSearchParams(data);

        let url = $form.attr('data-action') || $form.attr('action');
        let query = (url.indexOf('?') >= 0 ? '&' : '?') + 'page=' + curIndex($stepView);
        let method = $form.data('method') || $form.attr('method') || 'POST';

        pendingRequest = fetch(url + query, {
            method: method,
            headers: {
                'pragma' : 'no-cache',
                'cache-control': 'no-cache'
            },
            body: params
        });

        showNotification(pendingRequest);

        pendingRequest.then((res) => {
            return res.clone().json();
        }).then((res) => {
            if (res.success) {
                if (res.redirectURL) {
                    window.location.href = res.redirectURL;
                } else if(res.resultHtml) {
                    navigateTo(curIndex($stepView) + 1, $stepView);

                    let $result = $stepView.find('.js-step-view__result');
                    $result.html(res.resultHtml);
                    initModulesInScope($result);

                } else if(res.html) {
                    let $result = $stepView.find('.js-step-view__content');
                    $result.html(res.html);
                    initModulesInScope($result);
                } else {
                    navigateTo(curIndex() + 1);
                }

            }

            $stepFormLoading.attr('hidden', true);
        }).catch((error) => {
            if (error.name !== 'AbortError') {
                $stepFormLoading.attr('hidden', true);
                console.error(error);
            }
        });
    }

    function curIndex($stepView) {
        let $steps = $stepView.find('.js-step-view__step');
        return $steps.index($steps.filter('.is-current'));
    }

    function navigateTo(index, $stepView) {
        let $steps = $stepView.find('.js-step-view__step');
        $steps.removeClass('is-current').eq(index).addClass('is-current');
    }
}

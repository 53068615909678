import $ from 'jquery';
import './scripts/common';
import onInitModulesInScope from './scripts/onInitModulesInScope';
import initModulesInScope from "@elements/init-modules-in-scope";
import * as headerImgSwitch from './scripts/headerImgSwitch';
import * as newsletterState from './scripts/newsletterState';
/*import * as brandBar from './scripts/brandBar';*/
import * as scrollTop from './scripts/scrollTop';
import * as friendlyRecaptcha from './scripts/friendlyRecaptcha';
import * as gallerySlider from './scripts/gallerySlider';
import * as thumbSlider from './scripts/thumbSlider';
import * as lightbox from './scripts/lightbox';
import * as slider from '@elements/slider';
import * as ajaxForm from '@elements/ajax-form';
import * as ajaxFormScroll from './scripts/ajaxFormScroll';
import * as accordionScrollTo from './scripts/accordionScrollTo';
import * as showForm from './scripts/showForm';
import * as accountForm from './scripts/accountForm';
import * as productSlider from './scripts/productSlider';
import * as passwordStrength from './scripts/password-strength';
import * as ajaxCheck from './scripts/ajaxCheck';
import * as changeContentCheckbox from './scripts/changeContentCheckbox';
import * as smoothScroll from '@elements/smooth-scroll';
import * as ajaxFormMap from './scripts/ajax-form-map';
import * as centerActiveTab from './scripts/centerActiveTab';
import * as iframeResizing from './scripts/iframeResizing';
import * as stepView from './scripts/stepView';
import * as parsley from '@elements/parsley-bootstrap-validation';
import * as togglePassword from './scripts/togglePassword';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = window['app'] || {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
onInitModulesInScope(headerImgSwitch.initInScope);

onInitModulesInScope(newsletterState.initInScope);

/*onInitModulesInScope(brandBar.initInScope);*/

onInitModulesInScope(scrollTop.initInScope);

onInitModulesInScope(friendlyRecaptcha.initInScope);

onInitModulesInScope(gallerySlider.initInScope);

onInitModulesInScope(thumbSlider.initInScope);

onInitModulesInScope(lightbox.initInScope);

let recommendationSliderOptions = {
    prevArrow:"<button type='button' class='slick-prev' aria-label='prev'><span class='icon icon-arrow-left'></span></button>",
    nextArrow:"<button type='button' class='slick-next' aria-label='next'><span class='icon icon-arrow-right'></span></button>",
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4
};

if (matchMedia('(max-width: 541px)').matches) {
    recommendationSliderOptions.slidesToShow = 1.2;
} else if (matchMedia('(max-width: 1199px)').matches) {
    recommendationSliderOptions.slidesToShow = 3;
}

onInitModulesInScope(slider.createInitInScope('.js-recommendation-slider', recommendationSliderOptions));

onInitModulesInScope(ajaxForm.initInScope);

onInitModulesInScope(ajaxFormScroll.initInScope);

onInitModulesInScope(accordionScrollTo.initInScope);

onInitModulesInScope(showForm.initInScope);

onInitModulesInScope(accountForm.initInScope);

onInitModulesInScope(productSlider.initInScope);

onInitModulesInScope(passwordStrength.initInScope);

onInitModulesInScope(ajaxCheck.initInScope);

onInitModulesInScope(changeContentCheckbox.initInScope);

onInitModulesInScope(smoothScroll.initInScope);

onInitModulesInScope(ajaxFormMap.initInScope);

onInitModulesInScope(centerActiveTab.initInScope);

onInitModulesInScope(iframeResizing.initInScope);

onInitModulesInScope(stepView.initInScope);

onInitModulesInScope(parsley.initInScope);

onInitModulesInScope(togglePassword.initInScope);

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);

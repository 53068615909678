"use strict";

export function initInScope ($scope) {
    const   $formCheckbox = $scope.find('.js-form-checkbox input');


    $formCheckbox.on('click', (e) => {

        let target = $(e.target).closest('.js-form-checkbox').data('form-target');
        let $form = $scope.find('*[data-form-number='+ target +']');
        let $hideForms = $scope.find('.js-hidden-form')

        if(e.target.checked){
            $hideForms.addClass('d-none');
            $form.removeClass('d-none');
        }else{

            $form.addClass('d-none');
        }
    });
}
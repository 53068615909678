export function initInScope ($scope) {
    if($scope.length) {
        let iframes = $scope[0].querySelectorAll('.js-iframe-resizing');

        window.addEventListener('message', function (msg, ...rest) {
            if (typeof msg.data === 'object' && msg.data.height) {
                iframes.forEach(function (iframe) {
                    if (iframe.getAttribute('src').indexOf(msg.origin) >= 0) {
                        iframe.style.height = msg.data.height + 'px';
                        iframe.setAttribute('scrolling', 'no');
                    }

                })
            }
        }, false);

        iframes.forEach(function (iframe) {
            iframe.addEventListener('load', function () {
                iframe.contentWindow.postMessage('getHeight', '*');
            });
        });

        iframes.forEach(function (iframe) {
            iframe.contentWindow.postMessage('getHeight', '*');
        });
    }
}
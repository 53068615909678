'use strict';

import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill
import {showNotification} from "@elements/alert-notification";

export function initInScope($scope) {
    let $newsletterInputContainer = $scope.find('.js-newsletter-state');

    $newsletterInputContainer.each(function () {
        let url = $(this).data('newsletter-state');
        let $loading = $scope.find('.js-newsletter-state__loading');
        let pendingRequest;

        $loading.attr('hidden', false);

        pendingRequest = fetch( url , {
            method: 'GET'
        });

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if(res.success){
                if (res.isSubscribed || res.isGranted) {
                    $(this).find('input').prop("checked", true);
                    $(this).find('input').removeAttr('disabled');
                    $(this).find('.js-newsletter-state__subscribed').removeAttr('hidden').addClass('has-subscription');
                }else{
                    $(this).find('input').prop("checked", false);
                    setTimeout(function() {
                        if(!$('.js-newsletter-state__subscribed.has-subscription').length) {
                            $('.js-newsletter-state__not-subscribed').removeAttr('hidden');
                        }
                    }, 500);
                }
            } else {
                showNotification(pendingRequest);
            }
        }).catch((error) => {
            if (error.name !== 'AbortError') {
                /*Do error stuff*/
                console.error(error);
            }
        }).finally(() => {
            $loading.attr('hidden', true);
        } );
    });
}
"use strict";

const selectors = {
    base: '.js-change-content-checkbox'
};

export function initInScope ($scope) {
    // changes the content of the target element
    let $inputs = $scope.find(selectors.base);

    $inputs.on('change', function () {
        let $this = $(this);
        let nameAffix = $this.is(':checked') ? 'checked' : 'unchecked'
        changeContent($this, nameAffix);
    });

}

export function changeContent($input, nameAffix) {
    let targetSelector = $input.data('change-content-target');

    if (targetSelector) {
        let $target = $(targetSelector);

        if ($target.length) {
            let dataKey = 'change-content-' + nameAffix;
            $target.html($input.data(dataKey));
        }
    }
}
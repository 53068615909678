"use strict";
export function initInScope ($scope) {
    $scope.find('.js-accordion-scroll').on('shown.bs.collapse', function (e) {
        let panel = $(e.target).parent(),
            headerHeight = $('.js-affix-header').height();

        if(headerHeight) {
            $('html, body').animate({
                scrollTop: panel.offset().top - headerHeight + 50
            }, 400);
        } else {
            $('html, body').animate({
                scrollTop: panel.offset().top
            }, 400);
        }
    });
}
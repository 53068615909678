"use strict";

export function initInScope ($scope) {
    const   $productsliders =  $scope.find('.js-product-slider');


    if (!$productsliders.length) {
        return;
    }

    import('@elements/slider').then(() =>{
        $productsliders.each(function (i, productslider) {
            let $productslider = $(productslider),
                numOfSlides = $productslider.find('.js-product-teaser').length;

            let slidesToShow = 4; //numOfSlides > 4 ? 4 : numOfSlides;

            let productSliderOptions = {
                prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>',
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="icon icon-arrow-right" aria-hidden="true"></span></button>',
                infinite: false,
                slidesToShow: slidesToShow,
                slidesToScroll: slidesToShow,
                dots: true,
                cssEase: 'ease-in-out',
                touchThreshold: 100,
            };

            if (matchMedia('(max-width: 440px)').matches) {
                productSliderOptions.slidesToShow = 1.2;
                productSliderOptions.slidesToScroll = 1;
            } else if (matchMedia('(max-width: 991px)').matches) {
                productSliderOptions.slidesToShow = 3.2;
                productSliderOptions.slidesToScroll = 3;
            }
            $productslider.not('.slick-initialized').slick(productSliderOptions);
        });
    });
}
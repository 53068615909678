"use strict";

export function initInScope ($scope) {
    $scope.find('.js-header-img-switch-herren').on('shown.bs.tab', function (e) {
        $('.js-header-img-damen').attr('hidden','hidden');
        $('.js-header-img-herren').removeAttr('hidden');
    });
    $scope.find('.js-header-img-switch-damen').on('shown.bs.tab', function (e) {
        $('.js-header-img-herren').attr('hidden','hidden');
        $('.js-header-img-damen').removeAttr('hidden');
    });
}
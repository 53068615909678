export function initInScope ($scope) {
    $scope.find('.js-toggle-password__btn').on('click', function() {
        let toggle = $(this).attr('data-toggle-id');
        let pw = $('#' + toggle)

        if (pw.attr('type') === "password") {
            pw.attr('type', 'text')
            console.log(this)
            $(this).addClass( 'active')
        } else {
            pw.attr('type', 'password')
            $(this).removeClass( 'active')
        }
    })
}

"use strict";

export function initInScope ($scope) {
    const   $formCheckbox = $scope.find('.js-form-checkbox input');

    $formCheckbox.on('click', (e) => {
        let $form = $(e.target).closest('.js-form').find('.js-hidden-form');

        if(e.target.checked){
            $form.removeClass('d-none');
        }else{
            $form.addClass('d-none');
        }
    });
}